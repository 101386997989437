<template>
    <div class="dashboard-container" v-loading="loading">
      <!-- 添加或编辑类别 -->
  
     
  
      <!-- 主体内容 -->
     
        <el-table
          :header-cell-style="{ background: '#F2F6FC', color: '#606266' }"
          :data="list"
          border
          style="width: 100%"
          ref="list"
          class="emp_table"
          v-if="list"
        >
        
          <el-table-column
            align="center"
            prop="companyName"
            label="企业名称"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            align="center"
            prop="areaName"
            label="所在地区"
            :show-overflow-tooltip="true"
          />
  
        
          <el-table-column
            align="center"
            prop="address"
            label="企业地址"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="username"
            label="管理员姓名"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="tel"
            label="联系电话"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
  
  
          <el-table-column
            align="center"
            prop="account"
            label="管理员账号"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
        
          <el-table-column align="center" label="操作" width="251px">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click="goorder(scope.row)"
              >
                合同管理
              </el-button>
              <el-button
                type="text"
                size="small"
                @click="gouser(scope.row)"
              >
                企业人员
              </el-button>
             
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <!-- <pagination
          v-show="total > 0"
          :total="total"
          :page.sync="queryParams.pageNum"
          :limit.sync="queryParams.pageSize"
          @pagination="companyInfo"
        /> -->
     
    </div>
  </template>
  
  <script>
  import {
    companyInfo,
   
  
  } from "../../request/http";
  
  export default {
    data() {
      return {
        dialog: false,
      
        title: "", //弹出框标题
        loading: true, //加载动画
        showDialog: false, //显示隐藏
        list: "", // 列表
        total: 0, //总条数
        queryParams: {
          pageNum: 1,
          pageSize: 10,
          companyId:null,
        },
       
      
       
      };
    },
    created() {
        this.queryParams.companyId=Number(localStorage.getItem('companyId'))
    
      this.companyInfo();
  
    },
    methods: {
   
      gouser(row){
       
        let companyId = row.companyId;
        this.$router.push({
          path: "/companyuser",
          query: { companyId },
        });
      },
      goorder(row){
        let companyId = row.companyId;
        this.$router.push({
          path: "/contract",
          query: { companyId },
        });
      },
   
     
    
      //获取列表
      async companyInfo() {

        let { data } = await companyInfo( this.queryParams.companyId);
            console.log(data);
        this.list = Array(data.data);
        console.log(this.list);
        this.loading = false;
      },
    
  
     
     
   
  
     
    
     
    },
  };
  </script>
  
  <style>
  </style>